<template>
  <div class="campaign-affiliate-reporting-metrics">
    <portal to="app-right-sidebar-title">
      <reporting-navigation-header :breadcrumbs="breadcrumbs" />
    </portal>
    <div class="affiliate-reporting-body">
      <div class="reporting-metrics-header">
        <div class="reporting-metrics-title">
          <div class="reporting-metrics-title__group">
            <h3>Event Breakdown</h3>
            <filters-summary :report-options="reportOptions" />
          </div>
          <div class="reporting-metrics-title__btn-group">
            <affiliate-reporting-export-button :disabled="!reportData.length" :loading="loadingCsvFile" @download-csv="downloadReport" />
            <app-button
              v-if="showBackBtn"
              class="reporting-metrics-title__btn-group-back"
              @click="handleActionBack">
              <i class="el-icon-back hidden-sm-and-down" /> Back
            </app-button>
          </div>
        </div>
      </div>
      <div v-if="reportSubType" class="campaign-affiliate-reporting-metrics__tabs">
        <el-menu
          :default-active="reportSubType"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleReportTypeChange">
          <el-menu-item index="clicks">
            Clicks
          </el-menu-item>
          <el-menu-item index="sales">
            Sales
          </el-menu-item>
        </el-menu>
        <div class="campaign-affiliate-reporting-metrics__filters">
          <div class="campaign-affiliate-reporting-metrics__filters-group">
            <h5>Breakdown</h5>
            <period-type-filter v-model="periodType" :period-types="periodTypes" />
          </div>
          <div class="campaign-affiliate-reporting-metrics__filters-group">
            <h5>Group By</h5>
            <group-filter v-model="filterGroups" :subids="subids" />
          </div>
        </div>
        <div class="campaign-affiliate-reporting-metrics__table">
          <div class="click-reports">
            <affiliate-reporting-table
              :key="`reporting-table-${reportSubType}`"
              :table-data="reportData"
              :columns="reportColumns"
              :table-summary="reportSummary"
              :pagination="pagination"
              :sticky-app-header-height="60"
              :default-sort="sortState"
              :loading="fetchingReport"
              :skeleton-rows="5"
              sticky-sink-id="affiliate-metrics-sink"
              portal-to="app-right-sidebar-header"
              @cell-click="handleDrilldownClick"
              @on-sort="handleSort"
              @page-change="handlePageChange">
              <el-table-column
                v-for="(column, index) in reportColumns"
                :key="column"
                :sort-orders="sortOrders"
                :prop="column"
                :label="columnWhiteListMap[column].label"
                :min-width="columnWhiteListMap[column].minWidth"
                :align="columnWhiteListMap[column].align"
                :class-name="columnClassName"
                sortable="custom">
                <template slot-scope="scope">
                  <skeleton-box
                    v-if="fetchingReport"
                    :width="`${columnWhiteListMap[column].minWidth * 0.7}px`"
                    :height="'12px'"
                    :style="{ float: columnWhiteListMap[column].align || 'left' }" />
                  <span v-else :class="reportTableColumnClass(column)">
                    <i v-if="index === 0 && hasGroupBy" class="el-icon-arrow-right" />
                    {{ getFormattedColumn(scope.row, column) }}
                  </span>
                </template>
              </el-table-column>
            </affiliate-reporting-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TableColumn } from 'element-ui';
import AffiliateReportingExportButton from '@/components/affiliate/reporting/AffiliateReportingExportButton';
import AffiliateReportingTable from '@/components/tables/AffiliateReportingTable';
import AffiliateReportMixin from '@/mixins/AffiliateReportMixin';
import AppButton from '@/components/AppButton';
import FiltersSummary from '@/components/filters/FiltersSummary';
import GroupFilter from '@/components/filters/GroupFilter';
import PeriodTypeFilter from '@/components/filters/PeriodTypeFilter';
import ReportingNavigationHeader from '@/components/campaigns/ReportingNavigationHeader';
import SkeletonBox from '@/components/skeletons/SkeletonBox';

export default {
  name: 'CampaignAffiliateReportingMetrics',
  components: {
    AffiliateReportingExportButton,
    ReportingNavigationHeader,
    AffiliateReportingTable,
    'el-table-column': TableColumn,
    SkeletonBox,
    GroupFilter,
    PeriodTypeFilter,
    AppButton,
    FiltersSummary
  },
  mixins: [AffiliateReportMixin],
  props: {
    sortOrders: {
      type: Array,
      default: () => ['descending', 'ascending', null]
    }
  },
  computed: {
    breadcrumbs() {
      return [{ name: 'Reporting' }, { name: 'Metrics' }];
    },
    reportSubType() {
      return this.getReportSubtype(this.$route);
    },
    excludedColumns() {
      return [...(this.reportSubType === 'sales' ? ['clicks'] : [])];
    }
  },
  methods: {
    handleReportTypeChange(newType) {
      if (newType === this.reportSubType) return;

      this.updateRoute({
        ...this.getActiveRouteOptions(),
        reportSubType: newType,
        page: 1
      });
    }
  }
};
</script>
<style lang="scss">
.campaign-affiliate-reporting-metrics {
  margin-top: -$--clb-space-2;

  h3 {
    margin: 0;
  }

  h5 {
    margin: $--clb-layout-1 0 $--clb-space-2 0;
    font-size: $--clb-font-size-base;
  }

  .filters-summary {
    padding-top: $--clb-space-1;
  }

  .affiliate-reporting-body {
    padding: 0 $--clb-mobile-padding;

    .reporting-metrics-header {
      .reporting-metrics-title {
        margin-top: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__group {
          display: flex;
          flex-direction: column;
          flex: 7;
        }

        &__btn-group {
          display: flex;
          flex: 3;
          justify-content: flex-end;
        }

        &__btn-group-back {
          background: $--clb-color-secondary__dark;
          height: 40px;
          width: auto;
          margin-left: $--clb-layout-1 - 1;
        }
      }
    }
  }

  &__tabs {
    margin-top: $--clb-layout-1;

    .el-menu {
      .el-menu-item {
        height: auto;
        line-height: normal;
        padding: $--clb-space-1 $--clb-space-5;
      }
    }
  }

  &__filters {
    display: flex;
    flex-direction: column;

    &-group {
      margin-right: $--clb-layout-1;
    }
  }

  &__table {
    margin-top: $--clb-layout-1;
  }

  @media (min-width: $--sm) {
    .affiliate-reporting-body {
      padding: 0 $--clb-layout-2;
    }

    &__tabs {
      margin-top: $--clb-layout-2;
    }

    &__filters {
      flex-direction: row;
    }
  }
}
</style>
